// FETCHING BLOGS
export const FETCHING_BLOGS = "FETCHING_BLOGS";
export const FETCHING_BLOGS_SUCCEEDED = "FETCHING_BLOGS_SUCCEEDED";
export const FETCHING_BLOGS_FAILED = "FETCHING_BLOGS_FAILED";

// PAGINATION
export const CLEAR_PAGINATION = "CLEAR_PAGINATION";
export const CLEAR_BLOGS = "CLEAR_BLOGS";

// FETCHING CATEGORIES
export const FETCHING_CATEGORIES = "FETCHING_CATEGORIES";
export const FETCHING_CATEGORIES_SUCCEEDED = "FETCHING_CATEGORIES_SUCCEEDED";
export const FETCHING_CATEGORIES_FAILED = "FETCHING_CATEGORIES_FAILED";

// FETCHING SUBCATEGORIES
export const FETCHING_SUBCATEGORIES = "FETCHING_SUBCATEGORIES";
export const FETCHING_SUBCATEGORIES_SUCCEEDED =
  "FETCHING_SUBCATEGORIES_SUCCEEDED";
export const FETCHING_SUBCATEGORIES_FAILED = "FETCHING_SUBCATEGORIES_FAILED";
export const CLEAR_SUBCATEGORY = "CLEAR_SUBCATEGORY";

// UPDATING KEYWORD SEARCH
export const UPDATE_SEARCH_QUERY = "UPDATE_SEARCH_QUERY";
export const UPDATE_STATIC_SEARCH_QUERY = "UPDATE_STATIC_SEARCH_QUERY";

// UPDATING SELECTED CATEGORY
export const UPDATE_SELECTED_CATEGORY = "UPDATE_SELECTED_CATEGORY";
export const UPDATE_SELECTED_SUBCATEGORY = "UPDATE_SELECTED_SUBCATEGORY";
export const CLEAR_SELECTED_CATEGORY = "CLEAR_SELECTED_CATEGORY";
export const CLEAR_SELECTED_SUBCATEGORY = "CLEAR_SELECTED_SUBCATEGORY";

// REQUESTING ARTICLE //
export const REQUESTING_ARTICLE = "REQUESTING_ARTICLE";
export const REQUESTING_ARTICLE_SUCCEEDED = "REQUESTING_ARTICLE_SUCCEEDED";
export const REQUESTING_ARTICLE_FAILED = "REQUESTING_ARTICLE_FAILED";
