const Color = {
  // Primary colours
  white: "#ffffff",
  black: "#222222",
  hiredlyPurple: "#512acc",
  buttonBlack: "#2E2E2E",

  // Secondary Colours
  lightPurple: "#efeffd",
  darkPurple: "#40249b",
  linkedInBlue: "#0072b1",
  facebookBlue: "#1877f2",
  purpleGradient:
    "linear-gradient(90deg, #983eba 0%, #6d32c5 38.85%, #512acc 100%)",
  lightHiredlyPurple: "rgba(81, 42, 204, 0.1)",
  buttonBorderPurple: "#8464dc",

  // Accent Colours
  accentYellow: "#f2af29",

  // State Colours
  error: "#f75443",
  success: "#90b938",
  blue: "#213db5",
  lightBlue: "#213db524",
  reject: "#be4242",
  warn: "#f2a647",

  // Grey Colours
  backgroundGrey: "#f5f5f5",
  homepageGrey: "#fafafa",
  lightGrey: "#efefef",
  darkGrey: "#707070",
  grey: "#d8d8d8",
  secondaryGrey: "#aeaeae",
  buttonGrey: "#353535",

  // Chip Colours
  chipGrey: "#000000",
  chipYellow: "#c0840c",
  chipGreen: "#7a9d2f",

  // Button Green
  buttonGreen: "#6EC197",
  buttonHoverGreen: "#37b074",

  // Text Colours
  textHighEmphasis: "rgba(0, 0, 0, 0.87)",
  textMediumEmphasis: "rgba(0, 0, 0, 0.60)",
  textDisabled: "rgba(0, 0, 0, 0.38)",
};

export default Color;
