import * as types from "../types/advice_type";

const initialState = {
  // For blogs
  blogs: [],
  fetchingBlogs: false,
  // For blog categories
  categories: [],
  subCategories: [],
  fetchingCategories: false,
  fetchingSubCategories: false,
  selectedCategory: {},
  selectedSubcategory: {},
  // For keyword search
  searchQuery: "",
  staticSearchQuery: "",
  // For article request
  requestingArticle: false,
  articleRequested: false,
  // For pagination
  pagination: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For pagination
    case types.CLEAR_PAGINATION: {
      return {
        ...state,
        blogs: [],
        pagination: {},
        fetchingBlogs: true,
      };
    }

    case types.CLEAR_BLOGS: {
      return {
        ...state,
        blogs: [],
        pagination: {},
        fetchingBlogs: false,
      };
    }
    // For blogs
    case types.FETCHING_BLOGS_SUCCEEDED: {
      return {
        ...state,
        blogs: action.payload.newArticles,
        fetchingBlogs: false,
        pagination: action.payload.meta.pagination,
      };
    }
    case types.FETCHING_BLOGS: {
      return {
        ...state,
        fetchingBlogs: true,
      };
    }

    // For blog categories
    case types.FETCHING_CATEGORIES_SUCCEEDED: {
      return {
        ...state,
        categories: action.payload,
        fetchingCategories: false,
      };
    }
    case types.FETCHING_SUBCATEGORIES_SUCCEEDED: {
      return {
        ...state,
        subCategories: action.payload,
        fetchingSubCategories: false,
      };
    }
    case types.FETCHING_CATEGORIES: {
      return {
        ...state,
        fetchingCategories: true,
      };
    }
    case types.FETCHING_SUBCATEGORIES: {
      return {
        ...state,
        fetchingSubCategories: true,
      };
    }
    case types.UPDATE_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case types.UPDATE_SELECTED_SUBCATEGORY: {
      return {
        ...state,
        selectedSubcategory: action.payload,
      };
    }
    case types.CLEAR_SELECTED_SUBCATEGORY: {
      return {
        ...state,
        selectedSubcategory: {},
      };
    }
    case types.CLEAR_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: "",
      };
    }
    case types.CLEAR_SUBCATEGORY: {
      return {
        ...state,
        subCategories: [],
      };
    }

    // For search
    case types.UPDATE_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }

    case types.UPDATE_STATIC_SEARCH_QUERY: {
      return {
        ...state,
        staticSearchQuery: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
